import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box'; // Import Box for layout
import logo from './logo.png'; // Adjust the path based on your project structure
import { InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function IdDisplay() {
  const { id: uuid } = useParams();
  const [heatingRequirementHtml, setHeatingRequirementHtml] = useState('');

  useEffect(() => {
    fetch('https://hpc-italy.sendo.world/backend/solve/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ uuid }),
    })
    .then(response => response.text())
    .then(htmlResponse => {
      setHeatingRequirementHtml(htmlResponse);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }, [uuid]); // Dependency array includes uuid to re-run the effect if uuid changes

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: heatingRequirementHtml }} />
    </div>
  );
}




function App() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    fullName: '',
    email: '',
    mobilePhone: '',
  });
  const [showCustomerForm, setShowCustomerForm] = useState(true);
  const [questions, setQuestions] = useState([]); // Initialize questions as an empty array
  const [uuid, setUuid] = useState('');
  const [heatingRequirementHtml, setHeatingRequirementHtml] = useState("");
  const numericInputRef = useRef(null);
  const [previousQuestionType, setPreviousQuestionType] = useState('');
  const theme = useTheme();

  useEffect(() => {
    // Fetch data from the URL
    fetch('https://hpc-italy.sendo.world/backend/questions/')
      .then((response) => response.json())
      .then((data) => {
        // Set the fetched data as the questions
        setQuestions(data);
        console.log("DATAAAAA: ", data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);
  
  useEffect(() => {
    if (questions[currentQuestionIndex]?.type === 'num' && numericInputRef.current) {
      if (previousQuestionType !== 'num') {
        numericInputRef.current.focus();
      }
      setPreviousQuestionType('num');
    } else {
      setPreviousQuestionType(questions[currentQuestionIndex]?.type);
    }
  }, [currentQuestionIndex, questions]);
  

  
  const handleAnswerChange = (event) => {
    const newAnswers = { ...answers, [questions[currentQuestionIndex].id]: event.target.value };
    setAnswers(newAnswers);
    console.log('new answers: ', newAnswers)
    // Check if the current question is of type 'radios'
    if (questions[currentQuestionIndex].type === 'radios') {
      // Auto-advance to the next question or handle the end of the questionnaire
      setTimeout(() => {
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
          // If it's the last question, trigger the submit action
          handleSubmit(newAnswers);
        }
      }, 100); // Delay in milliseconds
    }
  };
  

  const goToNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };


  
  const handleSubmit = (finalAnswers = answers) => {
    const isConfirmed = window.confirm("Are you sure you want to submit?");
    console.log("current answers: ", finalAnswers);
    if (isConfirmed) {
      setSubmitted(true);
  
      fetch('https://hpc-italy.sendo.world/backend/save/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(finalAnswers),
      })
        .then(response => response.text())
      .then(uuid => {
        setUuid(uuid); // Store the UUID for later use
        console.log("UUID: ", uuid);
        fetchHeatingRequirement(uuid); // Direct call with the new UUID
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
    } else {
      // User canceled the submission
    }
  };
  

const handleCustomerDataSubmission = () => {
  if (uuid) {
    // Combine answers, UUID, and customer info
    const updatedPayload = { ...answers, uuid, customerInfo };

    fetch('https://hpc-italy.sendo.world/backend/save/', { // Assuming there's an endpoint for updating the data
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedPayload),
    })
    .then(response => {
      if (response.ok) {
        console.log('Data updated successfully with customer info');
        fetchHeatingRequirement(uuid);

        
      } else {
        console.error('Failed to update data with customer info');
      }
    })
    .catch(error => {
      console.error('Error updating data with customer info:', error);
    });
  } else {
    console.log("Missing UUID or incomplete customer info. Can't update.");
  }
};


const fetchHeatingRequirement = (uuid) => {
  fetch('https://hpc-italy.sendo.world/backend/solve/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uuid }),
  })
  .then(response => response.text())
  .then(htmlResponse => {
    setHeatingRequirementHtml(htmlResponse);
  })
  .catch(error => {
    console.error('Error:', error);
  });
};



  const restartQuestionnaire = () => {
    setCurrentQuestionIndex(0);
    setAnswers({});
    setSubmitted(false);
    setShowCustomerForm(true);
    setCustomerInfo({
      fullName: '',
      email: '',
      mobilePhone: '',
    });
    setUuid('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        handleSubmit();
      }
    }
  };
  
  
  function calculateHeatingRequirement(answers) {
    const area = parseFloat(answers.homeSize);
    const tempDifference = parseFloat(answers.desiredIndoorTemp) - parseFloat(answers.coldestMonthTemp);
    let uValue;
  
    switch (answers.insulationQuality) {
      case "Κακή": // Poor
        uValue = 2.5;
        break;
      case "Μέτρια": // Average
        uValue = 1.7;
        break;
      case "Καλή": // Good
        uValue = 1.4;
        break;
      default:
        uValue = 1.7; // Default to Average if not specified
    }
  
    const heatingRequirementKw = (area * 3.5 * uValue * tempDifference) / 1000;
    return heatingRequirementKw;
  }

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length <= 10) { // Allow typing up to 10 characters
      setCustomerInfo({ ...customerInfo, mobilePhone: value });
    }
  
    if (value.length === 10) { // Perform validation after 10 characters are entered
      const pattern = /^69\d{8}$/; // Pattern for '69' followed by 8 digits
      if (!pattern.test(value)) {
        alert('Invalid phone number'); // Show a popup alert box
        // Handle invalid phone number here
      }
    }
  };
  
  

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <Router>
      <Routes>
        <Route path="/id/:id" element={<IdDisplay />} />

        <Route path="/" element={
          <>
            <div className="App">
            {submitted ? (
              <div>
                <Box 
                  display="flex" 
                  justifyContent="space-between" 
                  alignItems="center"
                  height="100px" 
                  mb={2} 
                  style={{ backgroundColor: '#00363c' }}
                >
                  <img src={logo} alt="Logo" style={{ width: '180px', height: 'auto' }} />
                  <Button 
                    variant="outlined" 
                    onClick={restartQuestionnaire}
                    style={{
                      backgroundColor: 'white', 
                      color: '#00363c', 
                      border: '1px solid #00363c',
                      margin: '20px'
                    }}
                  >
                    Ricomincia
                  </Button>
                </Box>
                {showCustomerForm && (
                  <div className='personal_data_form'
                    style={{
                      margin: 'auto',
                      maxWidth: '900px',  // Corrected property name
                      textAlign: 'center'
                    }}>

                    <h2>Informazioni per il cliente</h2>
                    <TextField
                      style={{
                        margin: '10px'
                      }}
                      label="Full Name"
                      value={customerInfo.fullName}
                      onChange={(e) =>
                        setCustomerInfo({ ...customerInfo, fullName: e.target.value })
                      }
                      fullWidth
                    />
                    <TextField
                      style={{
                        margin: '10px'
                      }}
                      label="Email"
                      value={customerInfo.email}
                      onChange={(e) =>
                        setCustomerInfo({ ...customerInfo, email: e.target.value })
                      }
                      fullWidth
                    />
                    <TextField
                      style={{
                        margin: '10px'
                      }}
                      label="Mobile Phone"
                      value={customerInfo.mobilePhone}
                      onChange={handlePhoneChange}
                      fullWidth
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+30</InputAdornment>,
                      }}
                    />

                    <Button
                      id="submit-button"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const updatedAnswers = { ...answers, customerInfo };
                        setAnswers(updatedAnswers);
                        setShowCustomerForm(false);
                        handleCustomerDataSubmission();
                      }}
                      disabled={
                        customerInfo.mobilePhone.length !== 10 || !/^69\d{8}$/.test(customerInfo.mobilePhone) || // Check phone number
                        !/^\S+@\S+\.\S+$/.test(customerInfo.email) || // Standard email format validation
                        !/^[\w'Α-ω'-]+ [\w'Α-ω'-]+$/.test(customerInfo.fullName) // Check for two words in fullName, including Greek characters
                      }
                    >
                      Invia
                    </Button>


                    <Button
                      style={{
                        margin: '20px'
                      }}
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setShowCustomerForm(false);
                        setCustomerInfo({
                          fullName: 'NULL',
                          email: 'NULL',
                          mobilePhone: 'NULL',
                        });
                        const updatedAnswers = { ...answers, customerInfo };
                        setAnswers(updatedAnswers);
                        handleCustomerDataSubmission();
                      }}
                    >
                      Rifiuto(*)
                    </Button>
                    <p className='small text-red' style={{ color: theme.palette.warning.main }}>
                    (*) Il rifiuto del modulo con i dati personali comporterà <u>NON ricevere i risultati tramite SMS</u>.
                    </p>
                  </div>
                )}
                <Card>
                  <CardContent>
                    <h1 className='resultstitle'>Risultati:</h1>
                    <div dangerouslySetInnerHTML={{ __html: heatingRequirementHtml }} />
                  </CardContent>
                </Card>
              </div>
            ) : (
              <div>
                <Box 
                  display="flex" 
                  justifyContent="space-between" 
                  alignItems="center"
                  height="100px" 
                  mb={2} 
                  style={{ backgroundColor: '#00363c' }}
                >
                  <img src={logo} alt="Logo" style={{ width: '180px', height: 'auto' }} />
                  <Button 
                    variant="outlined" 
                    onClick={restartQuestionnaire}
                    style={{
                      backgroundColor: 'white', 
                      color: '#00363c', 
                      border: '1px solid #00363c',
                      margin: '20px'
                    }}
                  >
                    Ricomincia
                  </Button>
                </Box>
                <Card>
                <CardContent>
                  <h1>Calcolo della pompa di calore Sendo</h1>
                  {currentQuestion ? (
                    <div>
                      <h2>Domanda {currentQuestionIndex + 1} di {questions.length}</h2>
                      <p>{currentQuestion.text}</p>
                      {currentQuestion.type === "num" && (
                        <TextField
                          type="number"
                          value={answers[currentQuestion.id] || ''}
                          onChange={handleAnswerChange}
                          inputRef={numericInputRef}  // Set the ref here
                          onKeyDown={(e) => handleKeyPress(e)}  // Use onKeyDown instead of onKeyPress
                          fullWidth
                        />

                      )}
                      {currentQuestion.type === "radios" && (
                        <RadioGroup name={currentQuestion.id} value={answers[currentQuestion.id] || ''} onChange={handleAnswerChange}>
                          {currentQuestion.options.map((option) => (
                            <FormControlLabel value={option} control={<Radio />} label={option} key={option} />
                          ))}
                        </RadioGroup>
                      )}
                      <Box display="flex" justifyContent="space-between" mt={2}>
                        {currentQuestionIndex > 0 && (
                          <Button variant="outlined" color="warning" onClick={goToPreviousQuestion}>
                            Indietro
                          </Button>
                        )}
                        {currentQuestionIndex < questions.length - 1 ? (
                          <Button variant="contained" color="primary" onClick={goToNextQuestion}>
                            Prossimo
                          </Button>
                        ) : (
                          <Button variant="contained" color="primary" onClick={handleSubmit}>
                            Invio
                          </Button>
                        )}
                      </Box>
                    </div>
                  ) : (
                    <p>Nessuna domanda disponibile.</p>
                  )}
                </CardContent>
              </Card>
              </div>
            )}
          </div>
          </>
        }/>
      </Routes>
    </Router>
  );
}

export default App;
